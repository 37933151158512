jQuery(document).ready(function () {
    var uploadError = false,
        bodyNode = $('body'),
        locale = bodyNode.data('locale').length > 0 ? bodyNode.data('locale') : 'en',
        patientId = bodyNode.data('patient');

    Dropzone.options.imagesUpload = {
        thumbnailWidth: 92,
        thumbnailHeight: 92,
        maxFilesize: 10,
        acceptedFiles: 'image/jpeg,image/png,image/gif',
        dictDefaultMessage: 'Dateien hierher bewegen oder nach Klick auswählen',
        drop: function(file) {
            $('.dz-error-message').css("opacity", 0);
        },
        queuecomplete: function(e){
            if (!uploadError) {
                window.location.href = patientId ? "/" + locale + "/patient/" + patientId + "/images/list" : "/images/latest/list";
            } else {
                uploadError = false;
            }
        },
        error: function(file, response){
            uploadError = true;
            $('.dz-error-message').css("opacity", 1);

            var error;
            if (file.previewElement) {
                $(file.previewElement).addClass("dz-error");
            }

            if (typeof response != 'object') {
                error = response;
            } else {
                error = response.file[0];
            }

            var ref = file.previewElement.querySelectorAll("[data-dz-errormessage]");
            var results = [];
            for (var i = 0, len = ref.length; i < len; i++) {
                var node = ref[i];
                results.push(node.textContent = error);
            }
            return results;
        }
    };

    $(document).on('click', '.favorite-button', function(){
        var elem = $(this),
            url = elem.attr('data-url');

        $.post(url, {
            _token: $('input[name="_token"]').val()
        }, function(r){
            if(r.status == "success") {
                elem.toggleClass('active');
                elem.find('span').toggleClass('glyphicon-star');
                elem.find('span').toggleClass('glyphicon-star-empty');
            }
        });
    });

    $('.navbar-toggle').on('click', function(){
        if($(this).hasClass('collapsed')){
            $('.sidebar-menu').slideDown(function() {
                $('.main-content').hide();
            });
        } else {
            $('.main-content').show();
            $('.sidebar-menu').slideUp();
        }

        $(this).toggleClass('collapsed');
    });

    $('.view-toggle').on('click', function(e){
        var linkNode = $(this),
            url = linkNode.attr('href');

        if ($('.comparable-image:checked').length) {
            e.preventDefault();

            var ids = [];
            $('.comparable-image:checked').each(function(){
                ids.push($(this).attr('data-id'));
            });

            url += "&selected_images=" + ids;

            window.location.href = url;
        }
    });

    $(document).on('change', '.comparable-image', function(){
        $('.compare-error').hide();
    });

    $('.compare-images').on('click', function(){
        var ids = [];

        $('.comparable-image').each(function(){
            if ($(this).is(':checked')) {
                ids.push($(this).attr('data-id'));
            }
        });

        if (!ids.length) {
            $('.compare-error').show();
            return;
        }

        $.get('/' + locale + '/images/compare', {
            ids: ids
        }, function(r){
            if(r.status != 'success') {
                return;
            }

            var modal =  $('#main-modal');
            modal.find('.modal-dialog').addClass('modal-lg');
            modal.find('.modal-content')
                .empty()
                .html([
                    $('<div>', {
                        'class': 'modal-header',
                        'html': [
                            $('<h4>', {
                                'class': 'modal-title',
                                'text': 'Bilder vergleichen'
                            })
                        ]
                    }),
                    $('<div>', {
                        'class': 'modal-body',
                        'html': r.data.html
                    })
                ]);

            modal.modal('show');

            $('.image-container').each( function() {
                new ImageEditor(this);
            });
        });

    });

    $(document).on('click', '.delete-button', function(){
        var deleteButton = $(this),
            modal =  $('#main-modal');

        modal.find('.modal-dialog').addClass('modal-sm');
        modal.find('.modal-content')
            .empty()
            .html([
                $('<div>', {
                    'class': 'modal-header',
                    'html': [
                        $('<h4>', {
                            'class': 'modal-title',
                            'text': 'Löschen bestätigen'
                        })
                    ]
                }),
                $('<div>', {
                    'class': 'modal-body',
                    'html': [
                        $('<p>', {
                            'html': [
                                $('<p>', {
                                    'html': [
                                        'Möchten Sie dieses Bild ',
                                        $('<strong>',{
                                            'text': 'unwiderruflich löschen?'
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                $('<div>', {
                    'class': 'modal-footer',
                    'html': [
                        $('<button>', {
                            'class': 'btn btn-danger confirm-delete',
                            'text': 'Löschen'
                        }),
                        $('<button>', {
                            'class': 'btn btn-default',
                            'text': 'abbrechen',
                            'data-dismiss': "modal"
                        })
                    ]
                })
            ]);

        modal.find('.confirm-delete')
            .on('click', function(){
                modal.modal('hide');

                var url = deleteButton.attr('data-url');
                if (url) {
                    $.post(url, {
                        _token: $('input[name="_token"]').val()
                    }, function(r) {
                        if(r.status == "success") {
                            deleteButton.closest('.thumb')
                                .fadeOut(function() {
                                    $(this).remove();
                                })
                        }
                    });
                }
            });

        modal.modal('show');
    });

    $(document).on('click', '.share-image', function(){
        var url = $(this).attr('data-url'),
            modal =  $('#main-modal');

        modal.find('.modal-dialog').addClass('modal-sm');
        modal.find('.modal-content')
            .empty()
            .html([
                $('<div>', {
                    'class': 'modal-header',
                    'html': [
                        $('<h4>', {
                            'class': 'modal-title',
                            'text': 'Bild teilen'
                        })
                    ]
                }),
                $('<div>', {
                    'class': 'modal-body',
                    'html': []
                }),
                $('<div>', {
                    'class': 'modal-footer',
                    'html': [
                        $('<button>', {
                            'class': 'btn btn-primary',
                            'text': 'Schliessen',
                            'click': function() {
                                modal.modal('hide');
                            }
                        })
                    ]
                })
            ]);


        $.get(url + "?type=get", function(r) {
            console.log(r);

            if (r.status == 'exists') {
                setupCopyLink(modal, url, r.link, r.shareUrl);
            } else {
                setupCreateShareLink(modal, url);
            }

            modal.modal('show');
        });
    });

    function setupCreateShareLink(modal, endpointUrl) {
        modal.find('.modal-content')
            .find('.modal-body')
            .empty()
            .html([
                $('<div>', {
                    'class': 'form-group',
                    'html': [
                        $('<label>', {
                            'for': 'link-duration-input',
                            'text': 'Ablaufdatum'
                        }),
                        $('<div>', {
                            'class': 'input-group',
                            html: [
                                $('<input>', {
                                    'type': 'text',
                                    'class': 'form-control share-link-expire',
                                    'id': 'link-duration-input',
                                    'placeholder': '(optional)'
                                }),
                                $('<span>', {
                                    'class': 'input-group-btn',
                                    'html': [
                                        $('<button>', {
                                            'class': 'btn btn-default clear-link-expire',
                                            'type': 'button',
                                            'text': 'Zurücksetzen'
                                        })
                                    ]
                                }),
                            ]
                        })
                    ]
                }),
                $('<div>', {
                    'class': 'form-group',
                    'html': [
                        $('<button>', {
                            'text': 'Link erzeugen',
                            'class': 'btn btn-default get-share-link'
                        })
                    ]
                })
            ]);

        $('.share-link-expire').datetimepicker({
            locale: locale
        });

        $('.clear-link-expire').on('click', function() {
            $('.share-link-expire').val('');
        });

        $('.get-share-link').on('click', function() {
            $.get(endpointUrl + "?type=create&expiration_date=" + $('.share-link-expire').val(), function(r){
                if (r.status != 'success') {
                    return false;
                }

                setupCopyLink(modal, endpointUrl, r.link, r.shareUrl);
            });
        });
    }

    function setupCopyLink(modal, endpointUrl, linkObject, url) {

        var expirationNode = null;

        if (linkObject.expiration_date) {
            expirationNode = $('<div>', {
                'style': 'margin-top: 15px;',
                'text': 'Gültig bis: ' + linkObject.expiration_date
            })
        }

        modal.find('.modal-content')
            .find('.modal-body')
            .empty()
            .html([
                $('<div>', {
                    'class': 'form-group',
                    'html': [
                        $('<label>', {
                            'for': 'share-input',
                            'text': 'Url'
                        }),
                        $('<div>', {
                            'class': 'input-group',
                            'html' : [
                                $('<input>', {
                                    'type': 'text',
                                    'class': 'form-control',
                                    'id': 'share-input',
                                    'value': url
                                }),
                                $('<span>', {
                                    'class' : 'input-group-btn',
                                    'html' : [
                                        $('<button>', {
                                            'data-clipboard-target': '#share-input',
                                            'class': 'btn btn-default copy-url',
                                            'type' : 'button',
                                            'text': 'Kopieren'
                                        })
                                    ]
                                })
                            ]
                        }),
                        expirationNode,
                        $('<button>', {
                            'class': 'btn btn-danger cancel-shared-link',
                            'style': 'margin-top: 15px;',
                            'text': 'Link zurücksetzen'
                        })
                    ]
                })
            ]);

        new Clipboard('.copy-url');

        $('.cancel-shared-link').on('click', function() {
            $.get(endpointUrl + "?type=cancel", function (r) {
                if (r.type == "success") {
                    setupCreateShareLink(modal, endpointUrl);
                }
            });
        });
    }

    var autocompleteResponse =  function(event, ui) {
        if (ui.content.length === 0) {
            ui.content.push({
                id: 0,
                label: "Keine Daten gefunden...",
                value: "no-recipients"
            });
        }
    };

    var customer_search = $('#customer-search');
    customer_search.on("focus", function(){ this.select(); });
    customer_search.autocomplete({
        open: function(event, ui) {
            $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
        },
        source: customer_search.attr('data-source'),
        response: autocompleteResponse,
        minLength: 2,
        select: function( event, ui ) {
            if (ui.item.value == 'no-recipients') {
                return false;
            }

            customer_search
                .parent()
                .find('.patient-id')
                .on('change', function(){
                    $(this)
                        .parent()
                        .submit();
                })
                .val(ui.item.id)
                .trigger('change');
        }
    });


    var customer_change = $( ".patient-inline-edit");
    customer_change.on("focus", function(){ this.select(); });
    customer_change.autocomplete({
        source: customer_change.attr('data-source'),
        response: autocompleteResponse,
        minLength: 2,
        select: function( event, ui ) {
            if (ui.item.value == 'no-recipients') {
                return false;
            }

            customer_change.parent()
                .find('.patient-id')
                .val(ui.item.id);
        }
    });

    // masked input for datetime field
    //$('.datetime-input').mask("9999-99-99 99:99:99");
    $('.patient-id-select').select2();

    // inline data saving
    var didEdit = false, // to detect, if input field has been edited
        $imageData = jQuery('.image-data'),
        token = jQuery('input[name=_token]').val();

    $(document).on('change', '.patient-id-select', function(){
        var form = $(this).parents('form');
        saveForm(form);
    });

    function showSavedNotification(button) {
        if (button.parent().find('.saved-notification')) {
            button.parent().find('.saved-notification').remove();
        }

        button
            .after($('<span>', {
                text: " gespeichert",
                'class': 'text-success saved-notification pull-right'
            }));

        setTimeout(function () {
            button.parent().find('.saved-notification').fadeOut(function () {
                $(this).remove();
            });
        }, 3000);
    }


    $(document).on('click', '.save-list-item', function(){
        var button = $(this),
            form = button.parents('form');

        saveForm(form, function(){
            showSavedNotification(button);
        });
    });

    $imageData.on('keyup','input[type=text]', function( e ){
        var charCode = (e.which) ? e.which : event.keyCode;
        if(charCode !== 9 || charCode !== 18) // tab & alt
        {
            didEdit = true;
        }
    });

    $imageData.on('blur', 'input[type=text]', function( e ) {
        // submit field data if only user has edited the field
        if(didEdit === false)
        {
            return false;
        }
        var $form = $(this).parents('form');

        saveForm($form);
        didEdit = false;
    });

    var saveForm = function(form, callback) {
        jQuery.ajax({
            'url' : form.attr('action'),
            'type' : 'post',
            'data' : form.serialize(),
            'success' : $.proxy(function(callback, data) {
                if (typeof callback != 'undefined') {
                    callback();
                }
            }, this, callback)
        });
    };

    /** TAGS **/
    var tagFields = jQuery('.tag-inline-edit');

    if(tagFields.length && searchTagsURI)
    {
        tagFields.tagit({
            minLength: 2,
            allowDuplicates: false,
            tagSource: function( request, response ) {
                jQuery.ajax({
                    url: searchTagsURI,
                    data: { term:request.term },
                    dataType: "json",
                    success: function( data ) {
                        response( jQuery.map( data, function( item ) {
                            return {
                                label: item.title,
                                value: item.title
                            }
                        }));
                    }
                });
            },
            afterTagAdded: function(event, ui)
            {
                var $input = jQuery(event.target);

                // saving the tag
                jQuery.ajax({
                    url: $input.data('tag-add-action'),
                    data: { term:ui.tagLabel },
                    dataType: "json",
                    success: function( data ) {
                        console.log('Tag added');
                    }
                });
            },
            afterTagRemoved: function(event, ui)
            {
                var $input = jQuery(event.target);

                // removing the tag
                jQuery.ajax({
                    url: $input.data('tag-remove-action'),
                    data: { term:ui.tagLabel },
                    dataType: "json",
                    success: function( data ) {
                        console.log('Tag removed');
                    }
                });
            }
        });
    }

    /** Datepicker **/

    $('input.datetime-input').each(function() {
        $(this).datetimepicker({
            locale: locale,
            date: $(this).val()
        });
    });

    /** CONTINUOUS SCROLL **/
    jQuery('.jscroll').jscroll({
        callback: function(){
            $('.patient-id-select').select2();

            $('input.datetime-input').each(function() {
                $(this).datetimepicker({
                    locale: locale,
                    date: $(this).val()
                });
            });
        }
    });

    $('.image-container').each( function() {
        editor = new ImageEditor(this);
    });
});

var editor;

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}