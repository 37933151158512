"use strict";

var ImageEditor = function (node) {
    this.node = node;
    this.readOnly = false;
    this.config = null;
    this.canvas = null;
    this.saveUrl = null;
    this.image = null;
    this.ctx = null;
    this.lastX = null;
    this.lastY = null;
    this.dragStart = null;
    this.dragged = null;
    this.scaleFactor = 1.05;
    this.currentScale = null;
    this.mirroredHorizontal = false;
    this.landscapeChanged = false;
    this.defaultWidth = 600;
    this.maxScale = 5;
    this.minScale = 0.1;
    this.adjustments = {
        settings: [],

        set: function (key, value) {
            this.settings[key] = value;
        },

        get: function () {
            return this.settings;
        },

        clear: function () {
            this.settings = [];
        }
    };


    this.filters = {
        brightnessContrast: function (pixels, brightness, contrast) {
            var contrastFactor = (259 * (contrast + 255)) / (255 * (259 - contrast));

            var d = pixels.data;
            for (var i = 0, len = d.length; i < len; i += 4) {
                d[i] = (contrastFactor * (d[i] - 128) + 128) + brightness;
                d[i + 1] = (contrastFactor * (d[i + 1] - 128) + 128) + brightness;
                d[i + 2] = (contrastFactor * (d[i + 2] - 128) + 128) + brightness;
            }
            return pixels;
        }
    };


    this.nodes = {
        root: null,
        canvasContainer: null,
        brightness: null,
        contrast: null,
        range: null,
        zoomOut: null,
        zoomIn: null,
        rotateLeft: null,
        rotateRight: null,
        scale: null,
        fullscreen: null,
        closeFullscreen: null,
        reset: null,
        save: null,
        saveCopy: null,
        download: null
    };

    this.init();
};

ImageEditor.prototype.init = function () {

    //
    //// create a wrapper around native canvas element (with id="c")
    //var canvas = new fabric.Canvas('canvas');
    //
    ////var myGroup = new fabric.Group();
    ////canvas.add(myGroup);
    ////myGroup.set({ originX: 'center', originY: 'center' });
    //
    //fabric.Image.fromURL(this.node.getElementsByTagName('canvas')[0].getAttribute('data-image'), function(oImg) {
    //    oImg.scale(0.2);
    //    canvas.add(oImg);
    //    //myGroup.hasControls = true;
    //
    //    canvas.renderAll();
    //
    //    //oImg.filters.push(new fabric.Image.filters.Brightness({brightness: parseInt(100)}));
    //    oImg.filters.push(new fabric.Image.filters.Contrast({contrast: parseInt(100)}));
    //    oImg.applyFilters(canvas.renderAll.bind(canvas));
    //
    //});
    //
    //$('.download-image').on('click', function(){
    //    canvas.deactivateAll().renderAll();
    //
    //    var br = canvas.item(0).getBoundingRect();
    //
    //    $('.image-container').append($('<img/>', {
    //        src: canvas.toDataURL({
    //            format: 'png',
    //            left: br.left,
    //            top: br.top,
    //            width: br.width,
    //            height: br.height
    //        })
    //    }));
    //
    //});

    this.canvas = this.node.getElementsByTagName('canvas')[0];
    this.ctx = this.canvas.getContext('2d');
    this.lastX = this.canvasCenterX = this.canvas.width / 2;
    this.lastY = this.canvasCenterY = this.canvas.height / 2;
    this.saveUrl = this.canvas.getAttribute('data-save-url');
    this.image = new Image;
    this.readOnly = this.canvas.getAttribute('data-readonly') == "true";

    try {
        this.config = JSON.parse(this.canvas.getAttribute('data-config'));
    } catch(e) {
        this.config = {};
    }

    this.image.onload = $.proxy(this.onImageLoaded, this);
    this.image.src = this.canvas.getAttribute('data-image');

    this.bindTransformations();

    this.initNodes();

    if (this.isEditable()) {
        this.bindEventListeners();
    }

    $(window).on('resize', $.proxy(function() {
        this.adjustHeight();
    }, this));

    var copySaved = getParameterByName('copy');
    if (copySaved) {
        this.showSaveNotification();
    }

    return this;
};

/**
 * Mostly for IE
 */
ImageEditor.prototype.adjustHeight = function() {
    if (this.canvas.msToBlob) {
        //$(this.node).height($(this.node).width());
        $(this.canvas).height($(this.canvas).width());
    }
};

ImageEditor.prototype.onImageLoaded = function() {
    this.redraw();
    this.loadConfig();
    setTimeout($.proxy(function(){
        this.adjustHeight();
    }, this), 500);
};

ImageEditor.prototype.initNodes = function () {
    var editor = this;

    this.nodes.root = $(this.node);
    this.nodes.toggleSettings = this.nodes.root.find('.editor-toggle');
    this.nodes.canvasContainer = this.nodes.root.find('.canvas-container');
    this.nodes.brightness = this.nodes.root.find('.filter-input.brightness');
    this.nodes.contrast = this.nodes.root.find('.filter-input.contrast');
    this.nodes.range = this.nodes.root.find('.zoom-range');
    this.nodes.zoomOut = this.nodes.root.find('.zoom-out');
    this.nodes.zoomIn = this.nodes.root.find('.zoom-in');
    this.nodes.rotateLeft = this.nodes.root.find('.rotate-left');
    this.nodes.rotateRight = this.nodes.root.find('.rotate-right');
    this.nodes.scale = this.nodes.root.find('.scale');
    this.nodes.fullscreen = this.nodes.root.find('.fullscreen');
    this.nodes.closeFullscreen = this.nodes.root.find('.close-fullscreen');
    this.nodes.reset = this.nodes.root.find('.reset-config');
    this.nodes.save = this.nodes.root.find('.save-config');
    this.nodes.saveCopy = this.nodes.root.find('.save-copy');
    this.nodes.download = this.nodes.root.find('.download-image');

    this.nodes.toggleSettings.on('click', function() {
        $(this).parent().toggleClass('open');
    });

    var filterChangeCallback = function () {
        var input = $(this);
        var filter = input.data("filter");
        var value = input.val();

        input.parent().parent().find(".value").html(value);

        editor.adjustments.set(filter, value);
        editor.redraw();
    };

    this.nodes.brightness.on('input change', filterChangeCallback);
    this.nodes.contrast.on('input change', filterChangeCallback);

    this.nodes.range.on('input change', function () {
        var input = $(this),
            nextScale = parseFloat(input.val()),
            transform = editor.getTransform(),
            oldScale = transform.a,
            ratio,
            normalize = editor.mirroredHorizontal ? -1 : 1;

        if (editor.landscapeChanged) {
            oldScale = transform.b;
        }

        oldScale = oldScale * normalize;

        if (oldScale < editor.minScale) {
            oldScale = editor.minScale;
        }

        ratio = nextScale / oldScale;

        editor.scale(ratio, ratio);
        editor.currentScale = nextScale;
    });

    this.nodes.zoomOut.on('click', function () {

        editor.lastX = editor.canvas.width / 2;
        editor.lastY = editor.canvas.height / 2;
        editor.zoom(-1);
    });

    this.nodes.zoomIn.on('click', function () {
        editor.lastX = editor.canvas.width / 2;
        editor.lastY = editor.canvas.height / 2;
        editor.zoom(1);
    });

    this.nodes.rotateLeft.on('click', function () {
        editor.landscapeChanged = !editor.landscapeChanged;
        editor.rotate(90);
    });

    this.nodes.rotateRight.on('click', function () {
        editor.landscapeChanged = !editor.landscapeChanged;
        editor.rotate(-90);
    });

    this.nodes.scale.on('click', function () {
        var scaleWidth = parseInt($(this).attr('data-scalewidth'));
        var scaleHeight = parseInt($(this).attr('data-scaleheight'));

        if (scaleWidth == -1) {
            editor.mirroredHorizontal = !editor.mirroredHorizontal;
        }

        editor.scale(scaleWidth, scaleHeight);
    });

    this.nodes.fullscreen.on('click', function () {
        if (screenfull.enabled) {
            screenfull.request(editor.nodes.canvasContainer.get(0));
        }
    });

    this.nodes.closeFullscreen.on('click', function () {
        screenfull.exit();
    });

    this.nodes.reset.on('click', function () {
        editor.reset();
    });

    var saveConfig = function () {
        var data = editor.getData(),
            imageData = editor.getImageData(),
            copy = $(this).attr('data-copy') == "true";

        if (imageData instanceof Blob) {
            //imageData =
            var reader = new window.FileReader();
            reader.readAsDataURL(imageData);
            reader.onloadend = function() {
                sendToServer(data, reader.result, copy);
                console.log(reader.result );
            }
        } else {
            sendToServer(data, imageData, copy);
        }
    };

    var sendToServer = function(data, imageData, copy) {
        // jQuery breaks IE when sending blob
        var xhr = new XMLHttpRequest();
        xhr.open('post', editor.saveUrl);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function() {
            if (xhr.status === 200) {
                var r = JSON.parse(xhr.responseText);

                if (r.status == "success") {
                    if (copy) {
                        window.location.href = r.data.url;
                        return true;
                    }

                    editor.showSaveNotification();
                }
            }
        };
        xhr.send(JSON.stringify({
            _token: $('input[name="_token"]').val(),
            config: data,
            image: imageData,
            copy: copy
        }));
    };

    this.nodes.save.on('click', saveConfig);
    this.nodes.saveCopy.on('click', saveConfig);

    this.nodes.download.on('click', function () {
        editor.download(this);
    });
};

ImageEditor.prototype.showSaveNotification = function () {
    var editor = this;

    if (editor.nodes.root.find('.saved-notification')) {
        editor.nodes.root.find('.saved-notification').remove();
    }

    editor.nodes
        .save
        .parent()
        .append($('<span>', {
            text: " gespeichert",
            'class': 'text-success saved-notification'
        }));

    setTimeout(function () {
        editor.nodes.root.find('.saved-notification').fadeOut(function () {
            $(this).remove();
        });
    }, 3000);
};

ImageEditor.prototype.redraw = function () {
    this.clear();

    //scale to fit canvas on load
    if (!this.currentScale) {
        this.scaleToFitBounds();
    }

    this.ctx.drawImage(
        this.image,
        this.canvasCenterX - (this.image.width / 2),
        this.canvasCenterY - (this.image.height / 2)
    );

    this.adjust();
};

ImageEditor.prototype.loadConfig = function () {
    var config = this.config;

    if (jQuery.isEmptyObject(config)) {
        return false;
    }

    this.landscapeChanged = config.landscapeChanged == "true";
    this.mirroredHorizontal = config.mirroredHorizontal == "true";

    if (!!config.adjustments && config.adjustments.length > 0) {
        for (var i in config.adjustments) {
            if ( !config.adjustments.hasOwnProperty(i) ) {
                continue;
            }

            var adjustment = config.adjustments[i];
            this.adjustments.set(adjustment[0], parseFloat(adjustment[1]));

            if (this.isEditable()) {
                this.nodes[adjustment[0]]
                    .val(parseFloat(adjustment[1]))
                    .trigger('input');
            }
        }
    }

    this.setTransform(
        parseFloat(config.transform.a),
        parseFloat(config.transform.b),
        parseFloat(config.transform.c),
        parseFloat(config.transform.d),
        parseFloat(config.transform.e),
        parseFloat(config.transform.f)
    );

    var currentScale = parseFloat(config.transform.a);
    if (this.landscapeChanged) {
        currentScale = parseFloat(config.transform.b);
    }

    this.setCurrentScale(currentScale);
    this.redraw();
};

ImageEditor.prototype.clear = function () {
    var p1 = this.ctx.transformedPoint(0, 0);
    var p2 = this.ctx.transformedPoint(this.canvas.width, this.canvas.height);
    this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
};

ImageEditor.prototype.getData = function () {
    var adj = this.adjustments.get(),
        adjustments = [];

    for (var i in adj) {
        if ( !adj.hasOwnProperty(i) ) {
            continue;
        }

        adjustments.push([i, adj[i]]);
    }

    return {
        landscapeChanged: this.landscapeChanged,
        mirroredHorizontal: this.mirroredHorizontal,
        adjustments: adjustments,
        transform: {
            a: this.getTransform().a,
            b: this.getTransform().b,
            c: this.getTransform().c,
            d: this.getTransform().d,
            e: this.getTransform().e,
            f: this.getTransform().f
        }
    };
};

ImageEditor.prototype.adjust = function () {
    var pixels = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height),
        adjustments = this.adjustments.get(),
        brightness = typeof adjustments.brightness != "undefined" ? parseFloat(adjustments.brightness) : 0,
        contrast = typeof adjustments.contrast != "undefined" ? parseFloat(adjustments.contrast) : 0,
        imageData;

    if (brightness || contrast) {
        imageData = this.filters.brightnessContrast(pixels, brightness, contrast);
        this.ctx.putImageData(imageData, 0, 0);
    }
};

ImageEditor.prototype.zoom = function (clicks) {
    var pt = this.ctx.transformedPoint(this.lastX, this.lastY),
        factor = Math.pow(this.scaleFactor, clicks),
        transform = this.ctx.getTransform(),
        currentScale = transform.a,
        normalize = this.mirroredHorizontal ? -1 : 1;

    var imageBoundLeft = this.canvasCenterX - (this.image.width / 2) - 50,
        imageBoundRight = this.canvasCenterX + (this.image.width / 2) - 50,
        imageBoundTop = this.canvasCenterY - (this.image.height / 2) - 50,
        imageBoundBottom = this.canvasCenterY + (this.image.height / 2) - 50;


    if(pt.x < imageBoundLeft || pt.x > imageBoundRight || pt.y < imageBoundTop || pt.y > imageBoundBottom) {
        return false;
    }

    if (this.landscapeChanged) {
        currentScale = transform.b;
    }

    if (currentScale < this.minScale) {
        currentScale = currentScale * -1;
    }

    // if zooming out but limit reached
    if (clicks > 0 && currentScale >= this.maxScale) {
        return false;
    }

    // if zooming in but limit reached
    if (clicks < 0 && currentScale <= this.minScale) {
        return false;
    }

    this.ctx.translate(pt.x, pt.y);
    this.ctx.scale(factor, factor);
    this.ctx.translate(-pt.x, -pt.y);

    this.redraw();


    currentScale = this.ctx.getTransform().a;
    if (this.landscapeChanged) {
        currentScale = this.ctx.getTransform().b;
    }

    currentScale = currentScale * normalize;

    if (currentScale < this.minScale) {
        currentScale = currentScale * -1;
    }

    this.setCurrentScale(currentScale);
};

ImageEditor.prototype.rotate = function (degrees) {
    var radians = degrees * Math.PI / 180,
        pt = this.ctx.transformedPoint(this.canvas.width / 2, this.canvas.height / 2);

    this.ctx.translate(pt.x, pt.y);
    this.ctx.rotate(radians);
    this.ctx.translate(-pt.x, -pt.y);

    this.redraw();
};

ImageEditor.prototype.scale = function (scaleWidth, scaleHeight) {
    var pt = this.ctx.transformedPoint(this.canvas.width / 2, this.canvas.height / 2);

    this.ctx.translate(pt.x, pt.y);
    this.ctx.scale(scaleWidth, scaleHeight);
    this.ctx.translate(-pt.x, -pt.y);

    this.redraw();
};

ImageEditor.prototype.scaleToFitBounds = function () {
    var scale;
    if (this.image.width > this.image.height) {
        scale = this.canvas.width / this.image.width;
    } else {
        scale = this.canvas.height / this.image.height;
    }

    this.setCurrentScale(scale);

    this.ctx.translate(this.canvasCenterX, this.canvasCenterY);
    this.ctx.scale(this.currentScale, this.currentScale);
    this.ctx.translate(-this.canvasCenterX, -this.canvasCenterY);
};

ImageEditor.prototype.setCurrentScale = function (scale) {
    this.currentScale = scale;
    
    if (this.isEditable()) {
        if(scale < 0) {
            scale = scale * -1;
        }

        this.nodes.range.val(scale);
    }
};

ImageEditor.prototype.getImageData = function() {
    var data;
    if (this.canvas.msToBlob) { //for IE
        data = this.canvas.msToBlob();
    } else {
        data = this.canvas.toDataURL();
    }

    return data;
};

ImageEditor.prototype.download = function (link) {
    var imageData = this.getImageData();

    if (this.canvas.msToBlob) { //for IE
        window.navigator.msSaveBlob(imageData, "download-" + Math.floor(Date.now() / 1000) + ".png");
    } else {
        link.href = imageData;
        link.download = "download-" + Math.floor(Date.now() / 1000) + ".png";
    }
};

ImageEditor.prototype.isEditable = function () {
    return !this.readOnly;
};

ImageEditor.prototype.reset = function () {
    this.landscapeChanged = false;
    this.mirroredHorizontal = false;
    this.setTransform(1, 0, 0, 1, 0, 0);
    this.adjustments.clear();
    this.nodes.brightness.val(0).trigger('input');
    this.nodes.contrast.val(0).trigger('input');
    this.scaleToFitBounds();
    this.redraw();
};

ImageEditor.prototype.getTransform = function () {
    return this.ctx.getTransform();
};

ImageEditor.prototype.setTransform = function (a, b, c, d, e, f) {
    this.ctx.setTransform(a, b, c, d, e, f);
};

ImageEditor.prototype.bindTransformations = function () {
    var ctx = this.ctx;
    var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
    var svg_matrix = svg.createSVGMatrix();
    var svg_point = svg.createSVGPoint();
    var stubs = {};
    var savedTransforms = [];

    var Transformations = {

        getTransform: function () {
            return svg_matrix;
        },

        save: function () {
            savedTransforms.push(svg_matrix.translate(0, 0));
            return getStub('save').call(ctx);
        },

        restore: function () {
            svg_matrix = savedTransforms.pop();
            return getStub('restore').call(ctx);
        },

        scale: function (sx, sy) {
            svg_matrix = svg_matrix.scaleNonUniform(sx, sy);
            return getStub('scale').call(ctx, sx, sy);
        },

        rotate: function (radians) {
            svg_matrix = svg_matrix.rotate(radians * 180 / Math.PI);
            return getStub('rotate').call(ctx, radians);
        },

        translate: function (dx, dy) {
            svg_matrix = svg_matrix.translate(dx, dy);
            return getStub('translate').call(ctx, dx, dy);
        },

        transform: function (a, b, c, d, e, f) {
            var m2 = svg.createSVGMatrix();
            m2.a = a;
            m2.b = b;
            m2.c = c;
            m2.d = d;
            m2.e = e;
            m2.f = f;
            svg_matrix = svg_matrix.multiply(m2);
            return getStub('transform').call(ctx, a, b, c, d, e, f);
        },

        setTransform: function (a, b, c, d, e, f) {
            svg_matrix.a = a;
            svg_matrix.b = b;
            svg_matrix.c = c;
            svg_matrix.d = d;
            svg_matrix.e = e;
            svg_matrix.f = f;
            return getStub('setTransform').call(ctx, a, b, c, d, e, f);
        },

        transformedPoint: function (x, y) {
            svg_point.x = x;
            svg_point.y = y;
            return svg_point.matrixTransform(svg_matrix.inverse());
        }
    };

    var mockMethod = function (method) {

        if (method in ctx) {
            stubs[method] = ctx[method];
        }

        ctx[method] = Transformations[method];
    };

    var getStub = function (name) {

        if (name in stubs) {
            return stubs[name];
        }

        throw "Undefined stub " + name;
    };

    var bindMethods = function (ctx) {

        mockMethod('save');
        mockMethod('restore');
        mockMethod('scale');
        mockMethod('rotate');
        mockMethod('translate');
        mockMethod('transform');
        mockMethod('setTransform');

        ctx.getTransform = Transformations.getTransform;
        ctx.transformedPoint = Transformations.transformedPoint;

        return ctx;
    };

    this.ctx = bindMethods(ctx);
};

ImageEditor.prototype.setEventPosition = function (event) {
    var pointerX = event.pageX || event.center.x,
        pointerY = event.pageY || event.center.y;

    this.lastX = event.offsetX || (pointerX - this.canvas.offsetLeft);
    this.lastY = event.offsetY || (pointerY - this.canvas.offsetTop);
};

ImageEditor.prototype.handleScroll = function (event) {
    var delta = event.wheelDelta ? event.wheelDelta / 40 : (event.detail ? -event.detail : 0);
    if (delta) {
        this.zoom(delta);
    }
    return event.preventDefault() && false;
};

ImageEditor.prototype.handlePinch = function (event) {
    this.setEventPosition(event);

    var delta;
    if (this.lastPinchScale < event.scale) {
        delta = 1 * event.scale;
    } else {
        delta = -1 * event.scale;
    }

    this.lastPinchScale = event.scale;
    this.zoom(delta);
};

ImageEditor.prototype.handleMouseDown = function (event) {
    document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';

    this.setEventPosition(event);
    this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY);
    this.dragged = false;
};

ImageEditor.prototype.handleMouseMove = function (event) {

    this.dragged = true;
    this.setEventPosition(event);

    if (this.dragStart) {
        var pt = this.ctx.transformedPoint(this.lastX, this.lastY);
        this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
        this.redraw();
    }
};

ImageEditor.prototype.handleMouseUp = function (event) {
    this.dragStart = null;
};

ImageEditor.prototype.bindEventListeners = function () {
    var editor = this,
        mc = new Hammer.Manager(this.canvas, {touchAction: 'auto'});

    this.canvas.addEventListener('DOMMouseScroll', function (event) {
        editor.handleScroll(event);
    }, false);

    this.canvas.addEventListener('mousewheel', function (event) {
        editor.handleScroll(event);
    }, false);

    $(this.canvas).mousedown(function (event) {
        editor.handleMouseDown(event);
        console.log("pan");
    });

    $(this.canvas).mousemove(function (event) {
        editor.handleMouseMove(event);
    });

    $(document).mouseup(function (event) {
        editor.handleMouseUp(event);
    });

    mc.add(new Hammer.Pan({threshold: 0, pointers: 0}));
    mc.add(new Hammer.Pinch({threshold: 0})).recognizeWith([mc.get('pan')]);

    mc.on("panstart panend panmove", function (ev) {
        ev.preventDefault();

        if (ev.pointerType == "mouse") {
            return false;
        }

        if (ev.type == "panstart") {
            editor.handleMouseDown(ev);

        } else if (ev.type == "panend") {
            editor.handleMouseUp(ev);
        } else {
            editor.handleMouseMove(ev);
        }
    });

    mc.on("pinchstart pinchmove", function (ev) {
        editor.handlePinch(ev);
    });

    if (screenfull.enabled) {
        document.addEventListener(screenfull.raw.fullscreenchange, function () {
            var values,
                ratio;
            if (screenfull.isFullscreen) {

                console.log(screen.width)

                editor.nodes.closeFullscreen.show();

                if (screen.height > screen.width) {
                    ratio = screen.width / editor.canvas.width;

                    values = {
                        "maxWidth": screen.width + "px",
                        "width": screen.width + "px",
                        "height": (editor.canvas.height * ratio) + "px"
                    };
                } else {
                    ratio = screen.height / editor.canvas.height;

                    values = {
                        "maxWidth": (editor.canvas.width * ratio) + "px",
                        "width": (editor.canvas.width * ratio) + "px",
                        "height": screen.height + "px"
                    };
                }

                editor.nodes.canvasContainer.css(values);
                editor.nodes.canvasContainer.find('canvas').css(values);
            } else {
                console.log(editor.defaultWidth );
                values = {
                    "maxWidth": "auto",
                    "width": "100%",
                    "height": "auto"
                };

                editor.nodes.closeFullscreen.hide();
                editor.nodes.canvasContainer.css(values);

                values.width = "1000px";
                editor.nodes.canvasContainer.find('canvas').css(values);
            }
        });
    } else {
        if (editor.isEditable()) {
            editor.nodes.fullscreen
                .parent()
                .hide();
        }
    }
};